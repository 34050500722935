<template>
    <div :id="carouselId" class="carousel slide" data-bs-ride="carousel">

        <div class="carousel-indicators" v-if="slides.length > 1">
            <button
                v-for="(slide, index) in slides"
                :key="'indicator-' + index"
                type="button"
                :data-bs-target="'#' + carouselId"
                :data-bs-slide-to="index"
                :class="{ active: index === 0 }"
                :aria-label="'Slide ' + (index + 1)"
            ></button>
        </div>

        <div class="carousel-inner">
            <div
                v-for="(slide, index) in slides"
                :key="'slide-' + index"
                class="carousel-item"
                :class="{ active: index === 0 }"
                :data-bs-interval="slide.interval"
            >
                <ImageComponent :src="slide.image" :alt="slide.alt" className="object-fit-cover w-100 h-100" />
                <div class="carousel-caption mb-5 d-flex flex-column"  :class="slide.alignment">
                    <h3 class="d-none d-md-block mb-4 display-6"><span class="w-auto mb-3 py-2 px-4 bg-primary text-white shadow">{{ slide.title }}</span></h3>
                    <h4 class="d-none d-md-block"><span class="w-auto px-4 py-2 bg-grey-lighter text-dark shadow">{{ slide.description }}</span></h4>
                </div>
            </div>
        </div>

        <button
            v-if="slides.length > 1"
            class="carousel-control-prev"
            type="button"
            :data-bs-target="'#' + carouselId"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
    
        <button
            v-if="slides.length > 1"
            class="carousel-control-next"
            type="button"
            :data-bs-target="'#' + carouselId"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>
  
<script setup>
    import { ref, watch, defineProps } from 'vue';

    import ImageComponent from "./ImageComponent.vue"

    const props = defineProps({
        slides: {
            type: [Array, String],
            required: true,
            default: () => [],
        },
        carouselId: {
            type: String,
            default: "arangoIngenieraCarousel",
        },
    });

    const slides = ref([]);

    watch(
        () => props.slides,
        (newSlides) => {
            try {
                slides.value = typeof newSlides === "string" ? JSON.parse(newSlides) : newSlides;
            } catch (error) {
                console.error("Error al parsear slides:", error);
            }
        },
        { immediate: true }
    );
</script>
