<script setup>
    defineProps({
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: 'Title of Image'
        },
        className: {
            type: String,
            default: 'w-100'
        }
    });
</script>

<template>
    <img :src="src" :alt="alt" :class="className" />
</template>
  