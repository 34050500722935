import * as Popper from "@popperjs/core"
import * as bootstrap from 'bootstrap'
import '@coreui/coreui'
import '@fortawesome/fontawesome-free'

// Favicon
import '../img/favicon/apple-icon-precomposed.png'
import '../img/favicon/apple-icon.png'
import '../img/favicon/apple-icon-57x57.png'
import '../img/favicon/apple-icon-60x60.png'
import '../img/favicon/apple-icon-72x72.png'
import '../img/favicon/apple-icon-76x76.png'
import '../img/favicon/apple-icon-114x114.png'
import '../img/favicon/apple-icon-120x120.png'
import '../img/favicon/apple-icon-144x144.png'
import '../img/favicon/apple-icon-152x152.png'
import '../img/favicon/apple-icon-180x180.png'
import '../img/favicon/android-icon-36x36.png'
import '../img/favicon/android-icon-48x48.png'
import '../img/favicon/android-icon-72x72.png'
import '../img/favicon/android-icon-96x96.png'
import '../img/favicon/android-icon-144x144.png'
import '../img/favicon/android-icon-192x192.png'
import '../img/favicon/favicon-16x16.png'
import '../img/favicon/favicon-32x32.png'
import '../img/favicon/favicon-96x96.png'
import '../img/favicon/ms-icon-70x70.png'
import '../img/favicon/ms-icon-144x144.png'
import '../img/favicon/ms-icon-150x150.png'
import '../img/favicon/ms-icon-310x310.png'
import '../img/favicon/favicon.ico'

// Logos Navbar
import '../img/ai-navbar-logo.png'
import '../img/ai-navbar-logo-black.png'
import '../img/ai-navbar-logo-white.png'

// Logos
import '../img/ai-logo.png'
import '../img/ai-logo-black.png'
import '../img/ai-logo-white.png'

// Heroes
import '../img/about.webp'
import '../img/business.webp'
import '../img/contact.webp'
import '../img/services.webp'

// Background
import '../img/random.webp'
import '../img/gridtile.png'
import '../img/bgpattern.webp'
import '../img/brickwall.webp'

// About
import '../img/fundador.webp'
import '../img/firma.webp'
import '../img/mision.webp'
import '../img/vision.webp'
import '../img/valores.webp'

// Slider Home
import '../img/slider/slider-uno.webp'
import '../img/slider/slider-dos.webp'
import '../img/slider/slider-tres.webp'

// Icon logos
import '../img/icon-ai-white.png'
import '../img/icon-ai-black.png'
import '../img/icon-ai-blue.png'

// Services
import '../img/servicios/pen-tool.png'
import '../img/servicios/girder.png'
import '../img/servicios/house.png'
import '../img/servicios/estructuras-metalicas.webp'
import '../img/servicios/instalacion-cubiertas.webp'
import '../img/servicios/diseno-estructuras-metalicas.webp'
import '../img/servicios/obras-civiles.webp'
import '../img/servicios/asesorias.webp'

// Contact
import '../img/contact/clock.png'
import '../img/contact/mail.png'
import '../img/contact/marker.png'
import '../img/contact/phone.png'

// App
import '../scss/app'

import { createApp } from 'vue'

// Creación de la aplicación Vue
const app = createApp({
    data() {
        return {
            name: 'Arango Ingeniería SAS',
            version: '1.0.0'
        }
    }
})

import CarouselComponent from './components/CarouselComponent.vue'
// Registro de componentes
app.component('ai-carousel', CarouselComponent)

app.mount('#app');
